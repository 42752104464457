<template>
  <div class="body">
    <div class="header">
      <div class="info">累计：</div>
      <div>
        <div>{{attestationNum}}</div>
        <div>认证人数</div>
      </div>
      <div>
        <div>{{testNum}}</div>
        <div>检测人数</div>
      </div>
    </div>
    <div class="table">
      <p class="info">认证列表</p>
      <el-table :data="attestationData" v-loading="attestationLoading">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="SchoolName" label="学校名称" align="center"></el-table-column>
        <el-table-column prop="stuNum" label="学生人数" align="center"></el-table-column>
        <el-table-column prop="stuAuthNum" label="学生认证人数" align="center"></el-table-column>
        <el-table-column prop="guaAuthNum" label="家长认证人数" align="center"></el-table-column>
      </el-table>
      <el-pagination @current-change="attestationCurrentChange" :page-size="attestationPagesize" background
        layout="total, prev, pager, next, jumper" :current-page="attestationCurrpage" :total="attestationCountNum"
        style="margin-top:20px;text-align: right;">
      </el-pagination>
    </div>
    <div class="table">
      <p class="info">安防检测数据</p>
      <div class="search">
        <span>时间：</span>
        <el-date-picker class="time" size="medium" type="daterange" v-model="picker" start-placeholder="开始日期"
          range-separator="-" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd"
          unlink-panels @change="pickerChange">
        </el-date-picker>
      </div>
      <el-table :data="securityData" v-loading="securityLoading">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="SchoolName" label="学校名称" align="center"></el-table-column>
        <el-table-column prop="date" label="日期" align="center"></el-table-column>
        <el-table-column prop="stuNum" label="检测到学生人次" align="center"></el-table-column>
        <el-table-column prop="guaNum" label="检测到家长人次" align="center"></el-table-column>
        <el-table-column prop="pushNum" label="家长终端通知数量" align="center"></el-table-column>
        <el-table-column prop="openNum" label="APP打开数量" align="center"></el-table-column>
      </el-table>
      <el-pagination @current-change="securityCurrentChange" :page-size="securityPagesize" background
        layout="total, prev, pager, next, jumper" :current-page="securityCurrpage" :total="securityCountNum"
        style="margin-top:20px;text-align: right;">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    sclAuthenticatedList,
    machineArriveCountList,
    machineCountList
  } from '@/api/agentApi.js'
  export default {
    data() {
      return {
        securityData: [], //安防检测数据
        attestationData: [], //认证列表
        attestationNum: '256', //认证人数
        testNum: '256', //检测人数
        picker: null, //选择的时间
        attestationLoading: false, //加载框
        securityLoading: false, //加载框
        attestationPagesize: 10, // 每页显示条目个数
        attestationCurrpage: 1, // 初始页
        attestationCountNum: 0, // 总条数
        securityPagesize: 10, // 每页显示条目个数
        securityCurrpage: 1, // 初始页
        securityCountNum: 0, // 总条数
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近半个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }]
        }
      }
    },
    created() {
      this.getStatisticsData(); //获取头统计数据
      this.getAttestationData(1); // 获取认证列表数据
      this.getSecurityData(1); // 获取安防检测数据
    },
    methods: {
      async getStatisticsData() { // 获取头统计数据
        let res = await machineCountList(); //请求接口
        this.attestationNum = res.data.authNum; // 认证人数
        this.testNum = res.data.arriveNum; //监测人次
      },
      async getAttestationData(currpage) { // 获取认证列表数据
        this.attestationLoading = true;
        const data = {
          page: currpage, //	当前页数
          limit: this.attestationPagesize // 每页条数
        }
        let res = await sclAuthenticatedList(data); //请求接口
        this.attestationCountNum = parseInt(res.data.countNum); // 总条数
        this.attestationData = res.data.sclList; // 认证列表数据
        this.attestationCurrpage = currpage; //分页显示当前页
        this.attestationLoading = false;
      },
      async getSecurityData(currpage, selectPicker) { // 获取安防检测数据
        this.securityLoading = true;
        const data = {
          page: currpage, //	当前页数
          limit: this.securityPagesize, // 每页条数
          startDate: !!selectPicker ? selectPicker[0] : '', //开始时间
          endDate: !!selectPicker ? selectPicker[1] : '' //结束时间
        }
        let res = await machineArriveCountList(data); //请求接口
        this.securityCountNum = parseInt(res.data.countNum); // 总条数
        this.securityData = res.data.list; // 安防检测数据
        this.securityCurrpage = currpage; //分页显示当前页
        this.securityLoading = false;
      },
      pickerChange(e) { //选择日期
        this.getSecurityData(1, e); // 根据选取的日期获取安防检测数据
      },
      attestationCurrentChange(val) { // 认证列表分页
        this.getAttestationData(val); // 获取认证列表数据
      },
      securityCurrentChange(val) { // 安防列表分页
        this.getSecurityData(val); // 获取安防检测数据
      }
    }
  }

</script>

<style lang="scss" scoped>
  * {
    text-align: left;
  }

  .header {
    display: flex;
    align-items: center;

    div {
      margin-left: 50px;
      text-align: center;
    }

    .info {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .table {
    padding: 20px 50px;

    .info {
      font-size: 20px;
      font-weight: bold;
    }

    .search {
      display: flex;
      align-items: center;

      .time {
        margin-left: 20px;
        width: 300px;
      }
    }

  }

</style>
